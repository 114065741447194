<template>
    <div style="margin-left: 15%; margin-right: 15%; text-align: left; background-color: #fff; padding-bottom: 3rem;">
        <div style="margin: 2rem; margin-top: 0.5rem; padding: 1rem 1rem; padding-left: 0rem; height: 4rem; vertical-align: top;">
            <span style="font-size: 2rem; line-height: 3rem;">Exchange Code with Contribution</span>
        </div>
        <Card>
            <p slot="title" style="padding-left: 1rem;">
                <Icon type="md-done-all" size="20"/>
                Check the order information
            </p>
            <div>
                <p class="blockTitle">Project information</p>
                <div style=" padding-left: 1rem;">
                    <a style="display: block; font-size: 1.2rem; font-weight: 400; color: #328ef0;" :href="`/#/function?id=${projectId}`">
                        <Icon type="ios-bookmarks-outline" style="margin-right: 1rem;"/>
                        {{ projectName }}
                    </a>
                    <div class="rowAbstract">{{projectAbstract}}</div>
                    <span class="smallDes" style="margin-left: 2.3rem;">{{language}}</span>
                    <span class="smallDes">Updated on {{lastUpdated}}</span>
                </div>
            </div>
            <Divider/>
            <div >
                <p class="blockTitle">File selection</p>
                <!--				<Tree :data="treeData"  :load-data="loadData" show-checkbox @on-check-change="checkChange"  multiple style="margin-left: 2rem;"></Tree>-->
                <Tree :data="treeData" show-checkbox @on-check-change="checkChange" multiple
                      style="margin-left: 2rem;"></Tree>
            </div>
        </Card>
        <div>
            <p style="text-align: right; font-size: 1.3rem; margin: 1rem 1.5rem;">
                Amount payable: <span style="color: #e9ab01; font-size: 1.5rem;">{{investPrice}}</span></p>
            <!-- 购买按钮 -->
            <Button style="float: right;margin: 0 1.5rem;"
                    @click="exchangeFile()">Exchange
            </Button>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                projectId: '',
                projectName: '',
                projectAbstract: '',
                fakeprojectId: 'ctest3',
                branch: 'master',
                path: '.',
                language: '',
                lastUpdated: '',
                price: '',
                ECpath: '',
                downloadPath: '',
                downloading: false,
                investPrice: 0,
                totalLine: 0,
                pricePerHundred: 0,
                stack: [],
                treeData: [],
                selectedFile: [],
                folderRender: (h, {root, node, data}) => {
                    return h('span', {
                        style: {
                            display: 'inline-block',
                            width: '100%'
                        }
                    }, [
                        h('span', [
                            h('Icon', {
                                props: {
                                    type: 'ios-folder-outline'
                                },
                                style: {
                                    marginRight: '8px'
                                }
                            }),
                            h('span', data.title)
                        ])
                    ]);
                },
            }
        },
        methods: {
            fakeInit() {
                this.projectName = 'bailicangdu/vue2-elm';
                this.projectAbstract = `The Visual Understanding Environment (VUE) project at Tufts' Academic Technology department provides faculty and students with tools to successfully integrate digital resources into their teaching and learning. VUE provides a visual environment for structuring, presenting, and sharing digital information and an OKI-compliant software bridge for connecting to FEDORA-based digital repositories.`,
                this.language = 'java, vue';
                this.lastUpdated = '2021.1.18';
                this.price = '$66.6';
                this.treeData[0].render = this.folderRender;
            },
            init() {
                var Tself = this;
                this.projectId = this.$route.query.projectId;
                this.$axios({
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    method: 'post',
                    url: '/project/findById',
                    data: this.projectId
                }).then(function (response) {
                    var data = response.data.data.items;
                    Tself.projectName = data.name;
                    Tself.projectAbstract = data.abstractInfo;
                    Tself.language = data.languages;
                    Tself.lastUpdated = data.updateTime;
                    Tself.price = data.executableCodePrice;
                    Tself.ECpath = data.executableCodePath;
                    // Tself.treeData[0].render = Tself.folderRender;
                    Tself.getPricePerHundred();
                })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            getPricePerHundred() {
                let Tself = this
                this.$axios.get(
                    '/price/getCodePricePerHundred?projectId=' + this.projectId
                ).then(function (response) {
                    Tself.pricePerHundred = response.data.data.items
                })
            },
            exchangeFile () {
                let Tself = this;
                let userId = this.$store.getters.getUserId;
                if (userId === null) {
                    this.$Message.error("Please sign in first!")
                    return
                }
                let data = {
                    projectId: this.projectId,
                    userId: userId,
                    fileList: this.selectedFile,
                    amount: this.investPrice
                }
                this.$axios({
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    method: 'post',
                    url: '/payment/exchangeFile',
                    data: data
                }).then(function (response) {
                    let data = response.data.data.items
                    if (data !== undefined) {
                        Tself.$Message.success("success")
                    }else{
                        let data = response.data.data.error
                        Tself.$Message.error(data)
                    }
                }).catch(function (error) {
                    console.log(error);
                });

            },
            getChildNode(nowNode) {
                let Tself = this;
                var d1 = this.$route.query.projectId;
                let d2 = nowNode.reqPath;
                let d3 = this.branch;
                // nowNode.expand = true
                Tself.$axios.get(
                    '/pit/getAllFile?projectId='
                    + d1 + '&relPath='
                    + d2 + '&branch='
                    + d3
                ).then(function (response) {
                    // console.log(response)
                    var dto = [];
                    var data23 = Array.from(response.data);
                    dto = data23.map(function (item) {
                            if (item.dir) {
                                return {
                                    loading: false,
                                    title: item.file,
                                    absolutePath: item.absolutePath,
                                    isDir: item.isDir,
                                    tag: item.tag,
                                    commitDate: item.commitDate,
                                    message: item.message,
                                    dir: item.dir,
                                    line: item.line,
                                    check: false,
                                    checkAll: false,
                                    reqPath: d2 + '/' + item.file, //用于请求的相对地址
                                    children: [], //用来添加文件夹下内容
                                };
                            }
                            return {
                                title: item.file,
                                absolutePath: item.absolutePath,
                                isDir: item.isDir,
                                tag: item.tag,
                                check: false,
                                checkAll: false,
                                commitDate: item.commitDate,
                                message: item.message,
                                dir: item.dir,
                                line: item.line,
                                reqPath: d2 + '/' + item.file, //用于请求的相对地址
                            };
                        }
                    );
                    let nextNode = dto
                    if (nextNode !== null && nextNode !== undefined && nextNode !== "") {
                        nowNode.children = nextNode
                        for (let i = 0; i < nextNode.length; i++) {
                            if (nextNode[i].dir) {
                                Tself.getChildNode(nextNode[i])
                            }
                        }
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            },
            //获取整棵树的数据
            solveFiles() {
                let Tself = this;
                var d1 = this.$route.query.projectId;
                let d2 = this.path;
                let d3 = this.branch;
                this.path = '';
                this.$axios.get(
                    '/pit/getAllFile?projectId='
                    + d1 + '&relPath='
                    + d2 + '&branch='
                    + d3
                ).then(function (response) {
                    // console.log(response);
                    var data23 = Array.from(response.data);
                    Tself.treeData = data23.map(function (item) {
                            if (item.dir) {
                                return {
                                    title: item.file,
                                    absolutePath: item.absolutePath,
                                    isDir: item.isDir,
                                    tag: item.tag,
                                    expand: false,
                                    commitDate: item.commitDate,
                                    message: item.message,
                                    dir: item.dir,
                                    line: item.line,
                                    loading: false,
                                    check: false,
                                    checkAll: false,
                                    reqPath: item.file, //用于请求的相对地址
                                    children: [], //用来添加文件夹下内容
                                };
                            }
                            return {
                                expand: false,
                                title: item.file,
                                absolutePath: item.absolutePath,
                                isDir: item.isDir,
                                tag: item.tag,
                                commitDate: item.commitDate,
                                message: item.message,
                                dir: item.dir,
                                line: item.line,
                                check: false,
                                checkAll: false,
                                reqPath: d2 + item.file, //用于请求的相对地址
                            };
                        }
                    );
                    for (let i = 0; i < Tself.treeData.length; i++) {
                        if (Tself.treeData[i].dir) {
                            Tself.getChildNode(Tself.treeData[i])
                        }
                    }
                }).catch(function (error) {
                    Tself.isMember = false;
                    console.log(error);
                });
            },
            loadData(item, callback) {
                let Tself = this;
                let d1 = this.$route.query.projectId;
                let d2 = item.reqPath; //这里用的文件夹的相对地址
                let d3 = this.branch;
                this.$axios.get(
                    '/pit/getAllFile?projectId='
                    + d1 + '&relPath='
                    + d2 + '&branch='
                    + d3
                ).then(function (response) {
                    var dto = [];
                    var data23 = Array.from(response.data);
                    dto = data23.map(function (item) {
                            if (item.dir) {
                                return {
                                    loading: false,
                                    title: item.file,
                                    absolutePath: item.absolutePath,
                                    isDir: item.isDir,
                                    tag: item.tag,
                                    commitDate: item.commitDate,
                                    message: item.message,
                                    dir: item.dir,
                                    line: item.line,
                                    check: false,
                                    checkAll: false,
                                    reqPath: d2 + '/' + item.file, //用于请求的相对地址
                                    children: [], //用来添加文件夹下内容
                                };
                            }
                            return {
                                title: item.file,
                                absolutePath: item.absolutePath,
                                isDir: item.isDir,
                                tag: item.tag,
                                check: false,
                                checkAll: false,
                                commitDate: item.commitDate,
                                message: item.message,
                                dir: item.dir,
                                line: item.line,
                                reqPath: d2 + '/' + item.file, //用于请求的相对地址
                            };
                        }
                    );
                    callback(dto);
                }).catch(function (error) {
                    console.log(error);
                });
            },
            checkChange(allNode, currentNode) {
                console.log(allNode)
                let line = 0;
                for (let i = 0; i < allNode.length; i++) {
                    line += allNode[i].line
                }
                this.selectedFile = allNode
                this.investPrice = this.calculatePrice(line, this.pricePerHundred)
            },
            calculatePrice(line, pricePerHunderd) {
                let price = pricePerHunderd * line / 100
                price = price.toFixed(2)
                return price
            },
        },
        mounted() {
            this.init();
            this.solveFiles();
        }
    }
</script>

<style>
    .smallDes {
        color: darkgrey;
        font-size: 0.8rem;
        font-weight: 200;
        margin: 0 1rem;
    }

    .rowAbstract {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 0.875rem;
        margin-left: 2.2rem;
    }

    .blockTitle {
        font-weight: 600;
        margin-bottom: 0.6rem;
        margin-left: 1rem;
    }
</style>
